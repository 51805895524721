/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-this-in-sfc */
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CreateIcon from '@material-ui/icons/Create';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import AppBar from '@material-ui/core/AppBar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import NumberFormat from 'react-number-format';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import StoreIcon from '@material-ui/icons/Store';
import CloseIcon from '@material-ui/icons/Close';
import Toolbar from '@material-ui/core/Toolbar';
import SearchBar2 from '../../../components/SearchBar2';
import MenuTemporario from '../../../components/MenuTemporario';
import LayoutDadoItem from '../../../components/LayoutDadoItem';
import api from '../../../services/api';
import DialogoConfirmacao from '../../../components/DialogoConfirmacao';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: '96ch',
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  appBar: {
    position: 'relative',
  },
  lista: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  fontresponsiva: {
    fontSize: '0.9rem',
    '@media (min-width:600px)': {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.0rem',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function GridItensPedido2() {
  const classes = useStyles();
  const [isOpenConfirmacao, setisOpenConfirmacao] = React.useState(false);
  const [searchData, setSearchData] = useState([]);
  const [listaprodutoscarrinho, setlistaprodutoscarrinho] = useState([]);
  const [estoque, setEstoque] = useState([]);
  const [estoqueatual, setestoqueatual] = useState(0);

  // const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [produtoselecionado, setprodutoselecionado] = useState(null);
  const [desabilitabotaoenvio, setdesabilitabotaoenvio] = React.useState(false);
  const [qt, setQt] = useState('');
  const [totalproduto, setTotalProduto] = useState(0);
  const [descontoitem, setDescontoitem] = useState(0);
  const [isOpen, setisOpen] = useState(false);
  const [abaixodominimo, setabaixodominimo] = useState(false);
  const [precoacimadomaximo, setprecoacimadomaximo] = useState(false);
  const [state, setState] = useState({
    open: false,
    Transition: Fade,
    tipoalerta: 'success',
    Item: ''
  });

  const handleDialogConfirmacaoClose = async () => {
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
   

    setisOpenConfirmacao(false);
  };

  const handleAcaoDialogo = async () => {
    setdesabilitabotaoenvio(true);
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    
    setisOpenConfirmacao(false);
  };

  const getEstoqueProduto = async () => {
    const response = await api.get(
      '/api-pedidos/estoqueall',
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    setEstoque(response.data.data);
  };

  useEffect(() => {
    setlistaprodutoscarrinho([]);
    /*
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    async function fetchData() {
      const usuarioRef = doc(db, 'usuarios', vendedorlogado.email);
      const docSnap = await getDoc(usuarioRef);
      if (docSnap.exists()) {
        console.log('Document data:', docSnap.data());
        if (docSnap.data().produtoscarrinho && docSnap.data().produtoscarrinho.length > 0) {
          setisOpenConfirmacao(true);
        } else {
          setlistaprodutoscarrinho([]);
        }

        localStorage.setItem(
          'TIPOPEDIDO',
          JSON.stringify({ tipopedido: docSnap.data().tipopedido })
        );
        localStorage.setItem('DATAENTREGA', docSnap.data().dataentrega);
        localStorage.setItem('OBSERVACAOPEDIDO', docSnap.data().observacaopedido);
        localStorage.setItem(
          'PRODUTOSPEDIDO',
          JSON.stringify(docSnap.data().produtospedido)
        );
        localStorage.setItem(
          'CLIENTEPEDIDO',
          JSON.stringify(docSnap.data().clientepedido)
        );
        localStorage.setItem('PEDIDOREPRES', docSnap.data().pedidorepres);
      } else {
        // doc.data() will be undefined in this case
        console.log('No such document!');
        setlistaprodutoscarrinho([]);
      }
    } */

    getEstoqueProduto();
    localStorage.setItem('PRODUTOSCARRINHO', JSON.stringify([]));
    localStorage.setItem('PRODUTOSPEDIDO', JSON.stringify([]));
    setEstoque(JSON.parse(localStorage.getItem('ESTOQUEPRODUTO')));
    setabaixodominimo(false);
    setprecoacimadomaximo(false);
    setDescontoitem(0);
    // fetchData();
  }, []);

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  const handleCloseMenuRapido = () => {
    setisOpen(false);
  };

  const handleDialogClose = () => {
    setisOpen(false);
  };

  const BuscaEstoque = (param) => {
    const clientepedido = JSON.parse(localStorage.getItem('CLIENTEPEDIDO'));
    if (estoque) {
      const estoqueatualproduto = estoque.filter((x) => x.codestabelecimento === clientepedido.codestabelecimento && x.codigo === param.codigo);
      /* const estoqueatualproduto = estoque.filter((p) => {
        // eslint-disable-next-line max-len
        return p.codestabelecimento === clientepedido.codestabelecimento && p.codigo === param.codigo;
      }); */
      if (typeof estoqueatualproduto[0] !== 'undefined') {
        setestoqueatual(estoqueatualproduto[0].quantidade);
      } else {
        setestoqueatual(0);
      }
    }
  };

  const handleDialogOpen = async (v) => {
    setprodutoselecionado(v);
    await BuscaEstoque(v);

    setDescontoitem(0);
    setQt('');
    setTotalProduto(0);
    setisOpen(true);
  };

  const handleTotalItemPedido = (e) => {
    let dtotalproduto = 0;
    console.log(descontoitem);
    if (descontoitem === 0) {
      dtotalproduto = (Number(produtoselecionado.precomaximo) * e.target.value);
      setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
    } else {
      // eslint-disable-next-line max-len
      // MODELO ANTIGO BASEADO EM CALCULO DE DESCONTO
      // dtotalproduto = (produtoselecionado.precomaximo - ((produtoselecionado.precomaximo * (descontoitem / 100)))) * e.target.value;

      dtotalproduto = descontoitem * e.target.value;
      console.log(dtotalproduto);
      setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
    }
  };

  const handleTotalItemPedidoDesconto = (e) => {
    setabaixodominimo(false);
    if (Number(e.target.value) <= Number(produtoselecionado.precomaximo)) {
      setprecoacimadomaximo(false);
      let dtotalproduto = 0;
      if (e.target.value === 0) {
        dtotalproduto = (Number(produtoselecionado.precomaximo) * qt);
        setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
      } else {
        // eslint-disable-next-line max-len
        // MODELO ANTIGO BASEDO NO DESCONTO DO VALOR
        // dtotalproduto = (produtoselecionado.precomaximo - e.target.value) * qt;

        dtotalproduto = Number(e.target.value) * qt;
        setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
      }

      // console.log((e.target.value / produtoselecionado.precomaximo) * 100);

      /* MODELO ANTIGO DE APLICACAO DE VALIDACAO DE PRECO MINIMO.
    if (parseFloat(dtotalproduto / qt).toFixed(2) < Number(String(produtoselecionado.precominimo).replace(',', '.'))) {
      setabaixodominimo(true);
    } else {
      setabaixodominimo(false);
    } */

      if (Number(e.target.value) < Number(String(produtoselecionado.precominimo).replace(',', '.'))) {
        setabaixodominimo(true);
      } else {
        setabaixodominimo(false);
      }
    } else {
      let dtotalproduto = 0;
      dtotalproduto = Number(e.target.value) * qt;
      setTotalProduto(parseFloat(dtotalproduto).toFixed(2));
      // setprecoacimadomaximo(true);
    }
  };

  const pedidoemandamento = async (produtoscarrinho) => {
    const usuariologado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    const clientepedido = JSON.parse(localStorage.getItem('CLIENTEPEDIDO'));
    const tipopedido = JSON.parse(localStorage.getItem('TIPOPEDIDO'));
    const observacaopedido = localStorage.getItem('OBSERVACAOPEDIDO');
    const vendedorlogado = JSON.parse(localStorage.getItem('USUARIOLOGADO'));
    const dataentrega = localStorage.getItem('DATAENTREGA');
    const itempedido = [];
    produtoscarrinho.forEach((valor) => {
      itempedido.push({
        cd_pedido_palm: 'FDM48J', // GERAR AUTOMATICAMENTE NO PYTHON
        cd_cliente: clientepedido.codigo,
        dt_emissao: moment(new Date()).format('DD/MM/YYYY'),
        vr_pedido: valor.totalproduto,
        cd_org_venda: clientepedido.codestabelecimento,
        cd_pedido_cliente: 'FDM48J', // GERAR AUTOMATICAMENTE NO PYTHON
        cd_pedido: 'FDM48J', // GERAR AUTOMATICAMENTE NO PYTHON
        cd_cond_pgto: clientepedido.codpagamento,
        cd_tipo_pedido: String(tipopedido.tipopedido),
        dt_entrega: dataentrega,
        cd_meio_pgto: clientepedido.codpagamento,
        cd_st_pedido: '',
        id_prioridade: '',
        cd_vendedor: vendedorlogado.codigo,
        id_tipo_frete: 'CIF',
        cd_transportadora: '',
        ds_observacao: observacaopedido,
        nm_rz_social_ent: clientepedido.nome,
        ds_endereco_entr: '',
        nm_bairro_entr: '',
        nm_cidade_entr: '',
        nm_estado_entr: clientepedido.estado,
        nr_cep_entr: '',
        nr_fone_entr: '',
        nr_cnpj_cpf_entr: clientepedido.cnpj,
        nr_cgf_entr: '',
        cd_pedido_palm_pai: '',
        cd_tab_preco: clientepedido.codtabela,
        id_orcamento: '',
        cd_motivo: '',
        cd_produto: valor.codigo,
        produto: valor.descricao,
        qt_item: valor.quantidade,
        vr_item: valor.precomaximo,
        vr_item_original: valor.totalproduto,
        vr_item_minimo: valor.precominimo,
        pc_desconto: valor.desconto,
        cod_familia: valor.codfamilia,
        familiacomercial: valor.subfamilia,
        familia: valor.desfamilia, // MANDANDO FAMILIA
        subfamilia: valor.subfamilia // MANDANDO SUBFAMILIA
      });
    });
    
  };

  const handleAcao = async () => {
    if (abaixodominimo) {
      setState({
        ...state,
        open: true,
        Item: 'Produto abaixo do Valor Minimo',
        tipoalerta: 'error'
      });
    } else if (qt !== 0 && qt !== '') {
      const produtoscarrinho = JSON.parse(localStorage.getItem('PRODUTOSCARRINHO'));
      produtoselecionado.quantidade = qt;
      // ANTIGA FORMA BASEADO NO DESCONTO VALOR.
      // produtoselecionado.desconto = String(((descontoitem / produtoselecionado.precomaximo) * 100).toFixed(2));
      if (descontoitem === 0) {
        produtoselecionado.totalproduto = produtoselecionado.precomaximo * qt;
        produtoselecionado.desconto = '0';
      } else {
        produtoselecionado.totalproduto = descontoitem * qt;
        produtoselecionado.desconto = String(((1 - (descontoitem / produtoselecionado.precomaximo)) * 100).toFixed(2));
      }

      // TRATATIVA PARA PRECO ACIMA DA TEBELA
      if (descontoitem > produtoselecionado.precomaximo) {
        produtoselecionado.totalproduto = descontoitem * qt;
        produtoselecionado.desconto = '0';
      }

      // ANTIGA FORMA BASEADO NO DESCONTO VALOR.
      // produtoselecionado.totalproduto = totalproduto;

      const qtitem = produtoscarrinho.filter((p) => {
        // eslint-disable-next-line max-len
        return p.codigo === produtoselecionado.codigo;
      });
      if (qtitem.length > 0) {
        setState({
          ...state,
          open: true,
          Item: 'Produto já adicionado no pedido',
          tipoalerta: 'error'
        });
      } else {
        produtoscarrinho.push(produtoselecionado);
        setlistaprodutoscarrinho(produtoscarrinho);

        localStorage.setItem('PRODUTOSCARRINHO', JSON.stringify(produtoscarrinho));
        handleDialogClose();
        setState({
          ...state,
          open: true,
          Item: `${produtoselecionado.descricao} adicionado ao carrinho`,
          tipoalerta: 'success'
        });
      }
      // ADICIONA REGISTRO NA BASE DO FIREBASE
      pedidoemandamento(produtoscarrinho);
    } else {
      setState({
        ...state,
        open: true,
        Item: 'Favor informar quantidade',
        tipoalerta: 'error'
      });
    }
  };

  const setSource = (produto) => {
    try {      
      // eslint-disable-next-line global-require
      require(`../../../../public/static/images/produtos/${produto.codigo}.png`);
      return `/static/images/produtos/${produto.codigo}.png`;
    } catch (err) {
      return '/static/images/produtos/sem_foto.png';
    }
  };
  const handleInput = (e) => {
    const escolheudataentrega = String(localStorage.getItem('ESCOLHEUDATAENTREGA'));
    const pendenciaaprovacao = String(localStorage.getItem('PENDENCIAAPROVACAO'));
    const argumentosaprovacao = JSON.parse(localStorage.getItem('ARGUMENTOSAPROVACAO'));
    if ((pendenciaaprovacao === 'sim') && (argumentosaprovacao.bonificacao === '' && argumentosaprovacao.forarota === '')) {
      setState({
        ...state,
        open: true,
        tipoalerta: 'error',
        Item: 'Favor escolher um argumento para aprovação'
      });
    } else if (escolheudataentrega === 'nao') {
      setState({
        ...state,
        open: true,
        tipoalerta: 'error',
        Item: 'Favor escolher uma data de entrega'
      });
    } else {
      const produtoscarrinho = JSON.parse(localStorage.getItem('PRODUTOSPEDIDO'));
      if (produtoscarrinho !== null) {
        setData(produtoscarrinho.produtos);
        setSearchData(produtoscarrinho.produtos);
      } else {
        setData([]);
        setSearchData([]);
      }

      if (data) {
        const str = e.target.value;
        // setSearch(str);
        const newArr = data
          .filter(
            (item) => item.codigo.toLowerCase().includes(str.toLowerCase())
                || item.descricao.toLowerCase().includes(str.toLowerCase())
          )
          .map((item) => {
            const newTitle = item.codigo.replace(
              new RegExp(str),
              (match) => `${match}`
            );
            const newBody = item.descricao.replace(
              new RegExp(str),
              (match) => `${match}`
            );
            return {
              ...item,
              codigo: newTitle,
              descricao: newBody,
            };
          });

        setSearchData(newArr);
      } else {
        setSearchData([]);
        setState({
          ...state,
          open: true,
          tipoalerta: 'error',
          Item: 'Favor escolher um cliente'
        });
      }
    }
  };

  return (
    <>
      <DialogoConfirmacao
        isOpen={isOpenConfirmacao}
        handleClose={handleDialogConfirmacaoClose}
        handleAcao={handleAcaoDialogo}
        disabled={desabilitabotaoenvio}
        title="Pedido em digitação"
        subtitle="Deseja carregar os itens para o carrinho"
        textoconfirmacao="Carregar Itens"
      />
      <MenuTemporario isOpen={isOpen} handleClose={handleDialogClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleCloseMenuRapido} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {produtoselecionado
          && (
            <LayoutDadoItem
              handleAcao={handleAcao}
              desativabotao={precoacimadomaximo}
              produto={produtoselecionado.descricao}
              qttotal={qt}
              totaldesconto={descontoitem}
              totalpedido={totalproduto}
              valor={(
                <>
                  <NumberFormat value={Number(String(produtoselecionado.precomaximo).replace(',', '.'))} displayType="text" thousandSeparator prefix="" />
                </>
              )}
            >
              {precoacimadomaximo
                && (
                  <Alert severity="warning">
                    Preco acima do Maximo Permitido
                  </Alert>
                )}
              {abaixodominimo
                && (
                  <Alert severity="warning">
                    Abaixo do Minimo
                  </Alert>
                )}
              <List
                className={classes.lista}
              >
                <ListItem button key={produtoselecionado.precominimo}>
                  <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
                  <ListItemText primary={(
                    <>
                      <NumberFormat value={Number(String(produtoselecionado.precominimo).replace(',', '.'))} displayType="text" thousandSeparator prefix="Preço Minimo R$: " />
                    </>
                  )}
                  />
                </ListItem>
                {estoqueatual
                  && (
                    <ListItem button key="estoque">
                      <ListItemIcon><StoreIcon /></ListItemIcon>
                      <ListItemText primary={`Estoque: ${estoqueatual} CX`} />
                    </ListItem>
                  )}
              </List>
              <>
                <TextField
                  margin="dense"
                  id="quantidade"
                  label="Quantidade"
                  type="number"
                  variant="outlined"
                  value={qt}
                  onChange={(e) => {
                    setQt(e.target.value);
                    handleTotalItemPedido(e);
                  }}
                  // onBlur={() => handleTotalItemPedido()}
                  fullWidth
                />
                <TextField
                  margin="dense"
                  id="desconto"
                  type="number"
                  variant="outlined"
                  label="Valor de Venda"
                  value={descontoitem}
                  onChange={(e) => {
                    setDescontoitem(e.target.value);
                    handleTotalItemPedidoDesconto(e);
                  }}
                  fullWidth
                />
              </>
            </LayoutDadoItem>
          )}
      </MenuTemporario>
      <Snackbar
        open={state.open}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={state.Transition}
      >
        <Alert onClose={handleClose} severity={state.tipoalerta}>
          {state.Item}
        </Alert>
      </Snackbar>
      <SearchBar2 onInput={(e) => handleInput(e)} qtitems={listaprodutoscarrinho.length} />
      <List className={classes.root}>
        {searchData.map((produto) => (
          <>
            <ListItem alignItems="flex-start" key={produto.descricao}>
              <ListItemAvatar>
                <Avatar alt="Imagem" src={setSource(produto)} />
              </ListItemAvatar>
              <ListItemText
                className={classes.fontresponsiva}
                primary={produto.codigo}
                secondary={produto.descricao}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="editar" onClick={() => handleDialogOpen(produto)}>
                  <CreateIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="inset" component="li" />
          </>
        ))}
      </List>
    </>
  );
}
